import React from 'react';

import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Container } from '@components/Container';

import { DynamicInternal } from '../Footer/DynamicInternal';

import { CustomerSentiment } from './CustomerSentiment';
import { StaticFooter } from './StaticFooter';

export function Footer() {
  return (
    <Container>
      <BulkErrorBoundary location="Footer">
        <CustomerSentiment />
        <DynamicInternal />
        <StaticFooter />
      </BulkErrorBoundary>
    </Container>
  );
}
